import * as React from "react"
import { RecoilRoot } from "recoil"
import { Link, StaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"

import Layout from "../components/layout"
import SEO from "../components/seo"

const IndexPage = () => (
  <RecoilRoot>
    <Layout>
      <SEO title="Home" />
      <article className="flex flex-column items-center vh-75">
        <hgroup className="b--black-10 ba measure-wide center sans-serif">
          <h1 className="tc">Welcome to the homepage of Gabrielle Moncrease</h1>
          <div className="tc">
            <StaticQuery
              query={graphql`
                query {
                  placeholderImage: file(
                    relativePath: { eq: "Gabrielle_index.jpg" }
                  ) {
                    childImageSharp {
                      fixed(height: 200, fit: CONTAIN) {
                        ...GatsbyImageSharpFixed
                      }
                    }
                  }
                }
              `}
              render={data => (
                <Img
                  className="contain center"
                  imgStyle={{
                    marginLeft: "auto",
                    height: "100%",
                    backgroundSize: "contain",
                    objectFit: "contain",
                  }}
                  fixed={data.placeholderImage.childImageSharp.fixed}
                />
              )}
            />
          </div>
          <h2 className="tc i">Author, singer, mother</h2>
        </hgroup>
        <section className="measure lh-copy">
          <p>
            This website is the homepage for Gabrielle Moncrease, author of the
            book{" "}
            <Link
              className="link hover-light-blue i"
              to="/books/how-i-survived/"
            >
              How I Survived My Son&apos;s Suicide: Escape from Darkness
            </Link>
            .
          </p>
          <p>Keep posted for the latest news.</p>
        </section>
        <aside className="lh-copy measure">
          <Link to="/contact-us/">Get in contact with Gabrielle here</Link>
        </aside>
      </article>
    </Layout>
  </RecoilRoot>
)

// IndexPage.displayName = "HomePage"
export default IndexPage
